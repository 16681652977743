
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import { Table } from '@/interfaces/models/Table';
import headers from './headers';
import { Permission } from '@/enums/Permission';
import CupCode from '../../views/cupCode/CupCode.vue';
import CupCodeFilter from './CupCodeFilter.vue';

@Component({
  components: { VTable, CupCodeFilter },
})
export default class CupCodeTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Table[];
  @Prop({ type: Number, required: true }) public total!: number;

  public headers: ColumnHeader[] = headers;

  public maxCustomerGroups: number = 3;

  public $refs!: {
    table: InstanceType<typeof VTable> & { selected: any[] };
  };

  public canToggleActivation(val: boolean, item: CupCode) {
    return true;
  }

  public visibleGroups(customerGroups: string[]) {
    return customerGroups.filter((customerGroup: string, index: number) => index < this.maxCustomerGroups);
  }

  public isVisibleHidenGroups(customerGroups: string[]) {
    return customerGroups.length > this.maxCustomerGroups;
  }

  public canToggleVenueActivation(val: boolean) {
    if (val) {
      return this.$can(Permission.PROMO_CODE_ENABLE);
    }
    return this.$can(Permission.PROMO_CODE_DISABLE);
  }
}
