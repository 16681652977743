
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import { Table } from '@/interfaces/models/Table';
import headers from './headers';
import { Coupon } from '@/interfaces/models/Coupon';
import { Venue } from '@/interfaces/models/Venue';
import { Permission } from '@/enums/Permission';

@Component({
  components: { VTable },
})
export default class CouponTable extends Vue {
  @Prop({ type: String, required: false }) public customerGroup!: string;
  @Prop({ type: Array, required: true }) public items!: Table[];
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  @Prop({ type: Object, default: () => null }) public venue!: Venue;
  @Prop({ type: Array, default: () => [] }) public venueNames!: Array<Partial<Venue>>;

  public search: string = '';

  public $refs!: {
    table: InstanceType<typeof VTable> & { selected: any[] };
  };

  public canEdit(item: Coupon) {
    if (this.$isAdmin()) {
      return true;
    }
    if (!this.$can(Permission.COUPON_UPDATE)) {
      return false;
    }
    if (item.customerGroup && item.venues.length === 0 && !this.customerGroup) {
      return false;
    }
    if (item.venues && item.venues.length > 0) {
      for (const venue of item.venues) {
        if (!this.venues.find((v: Venue) => v._id === venue)) {
          return false;
        }
      }
    }

    return true;
  }

  @Watch('search')
  @Emit('search')
  public onSearch() {
    return this.search;
  }

  get headers() {
    if (!this.$can(Permission.COUPON_VIEW_SIMPLE) && !this.$can(Permission.COUPON_VIEW_GLOBAL)) {
      return headers;
    }
    if (this.$can(Permission.ADMIN)) {
      return headers;
    }

    if (this.$can(Permission.COUPON_VIEW_GLOBAL)) {
      return headers.filter((h: ColumnHeader) => h.value !== 'customerGroup');
    }

    if (this.$can(Permission.COUPON_VIEW_SIMPLE)) {
      return headers.filter((h: ColumnHeader) => !['customerGroup', 'countries', 'states'].includes(h.value));
    }

    // should actually never happen
    return [];
  }

  public edit(item: Coupon) {
    this.$router.push({ name: 'coupon.edit', params: { id: item._id }, query: { ...this.$route.query } });
  }

  public getVenueNames(item: Coupon) {
    return this.venueNames
      .filter((v: Partial<Venue>) => item.venues.includes(v._id as string))
      .map((v: Partial<Venue>) => v.name);
  }

  get codes() {
    // @ts-ignore
    return this.items.map((i: Coupon) => {
      return { ...i, disabledBy: null };
    });
  }

  public canToggleActivation(val: boolean, item: Coupon) {
    if (this.venues && this.venues.length > 0) {
      // if simple view, cannot deactivate global coupon
      if (this.$can(Permission.COUPON_VIEW_SIMPLE) && item.venues.length === 0) {
        return false;
      }

      const venueIds: string[] = this.venues.map((v: Venue) => v._id);
      for (const venue of item.venues) {
        if (!venueIds.includes(venue)) {
          return false;
        }
      }
    }

    if (val) {
      return this.$can(Permission.COUPON_ACTIVATE);
    }
    return this.$can(Permission.COUPON_DEACTIVATE);
  }

  public canToggleVenueActivation(val: boolean) {
    if (val) {
      return this.$can(Permission.COUPON_ENABLE);
    }
    return this.$can(Permission.COUPON_DISABLE);
  }

  get codesWithDisabledStatus() {
    return this.codes.map((c: Coupon) => {
      return {
        ...c,
        disabled: this.isDisabledByVenue(c),
      };
    });
  }

  public isDisabledByVenue(item: Coupon): boolean {
    return this.venue && this.venue.disabledCoupon && this.venue.disabledCoupon.includes(item._id);
  }
}
