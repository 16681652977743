
import { Component, Emit, Watch } from 'vue-property-decorator';
import CustomerGroup from '@/mixins/CustomerGroup';
import { mixins } from 'vue-class-component';
import { debounce } from '@/util/helper';

interface Selection {
  customerGroups?: string[] | null;
  from?: string | null;
  to?: string | null;
  active?: boolean | null;
  numFrom?: string | null;
  numTo?: string | null;
}

@Component
export default class CupCodeFilter extends mixins(CustomerGroup) {
  private _timerId: any = null;
  public fromMenu: boolean = false;
  public toMenu: boolean = false;

  public customerGroup: string[] | null = null;
  public from: string | null = null;
  public to: string | null = null;
  public active: boolean | null = null;
  public numFrom: string | null = null;
  public numTo: string | null = null;

  get selection() {
    const selection: Selection = {};

    if (this.active !== null) {
      selection.active = this.active;
    }
    if (this.from !== null && this.to !== null) {
      selection.from = this.from;
      selection.to = this.to;
    }
    if (this.numFrom !== null) {
      selection.numFrom = this.numFrom;
    }
    if (this.numTo !== null) {
      selection.numTo = this.numTo;
    }
    if (this.customerGroup !== null) {
      selection.customerGroups = this.customerGroup as unknown as string[];
    }

    return selection;
  }

  public debouncedSearch() {
    if (this._timerId) {
      clearTimeout(this._timerId);
    }
    const self = this;
    this._timerId = setTimeout(() => {
      self.$emit('change', self.selection);
    }, 500);
  }

  @Watch('selection')
  public onChange() {
    this.debouncedSearch();
  }
}
