
import { Component, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import Notification from '@/mixins/Notification';
import TableTable from '@/components/table/TableTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { mixins } from 'vue-class-component';
import Filter from '@/interfaces/api/Filter';
import { CupCode as CupCodeModel } from '@/interfaces/models/CupCode';
import { User } from '@/interfaces/models/User';
import CupCodeTable from '@/components/cupCode/CupCodeTable.vue';
import { VSelect } from 'vuetify/lib';
import CustomerGroup from '@/mixins/CustomerGroup';
import { toUrl } from '@/util/helper';
import { ExportType } from '@/enums/ExportType';
import { ValidationObserver } from 'vee-validate';
import moment from 'moment';
import ExportService from '../../api/http/ExportService';

const app = namespace('app');
const venue = namespace('venue');
const cupCode = namespace('cupCode');
const auth = namespace('auth');

@Component({
  components: { CupCodeTable, VEmptyState, TableTable, VWrapper },
})
export default class CupCode extends mixins(Notification, CustomerGroup) {
  @venue.State('active') public venue!: Venue;

  @app.State('venues') public venues!: Venue[];

  @auth.State('user') public user!: User;

  @cupCode.State('items') public promoCodes!: CupCodeModel[];
  @cupCode.State('filter') public filter!: Filter;
  @cupCode.State((state) => state.pagination.total) public total!: number;
  @cupCode.Action('fetch') public getCupCodes!: any;
  @cupCode.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @cupCode.Action('activateCupCode') public activateCupCode!: (data: { id: string }) => void;
  @cupCode.Action('deactivateCupCode') public deactivateCupCode!: (data: { id: string }) => void;
  @cupCode.Action('destroy') public deleteCupCode!: any;
  @cupCode.Action('toggleActive') public toggleManyActive!: (data: {
    customerGroups: string[];
    numFrom: string;
    numTo: string;
    active: boolean;
  }) => void;
  @cupCode.Action('deleteMany') public deleteMany!: (data: {
    customerGroups: string[];
    numFrom: string;
    numTo: string;
  }) => void;

  public exportCodesDialog: boolean = false;
  public actionCodesDialog: boolean = false;

  public exportCodesModel: {
    numberFrom: string;
    numberTo: string;
    customerGroups: string[];
    format: 'csv' | 'xlsx' | 'zip';
  } = {
    numberFrom: '',
    numberTo: '',
    customerGroups: [],
    format: 'csv',
  };

  public actionManyCodesModel: {
    numFrom: string;
    numTo: string;
    customerGroups: string[];
    active: boolean;
  } = {
    numFrom: '',
    numTo: '',
    customerGroups: [],
    active: false,
  };

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  public async mounted() {
    this.$startLoading('initCupCode');
    this.setFilter({});
    await this.getCupCodes({ page: 1 });
    this.$stopLoading('initCupCode');
  }

  public async filterCupCode(event: any) {
    this.setFilter({ ...event });
    await this.getCupCodes({ page: 1 });
  }

  public async toggleActivation({ item, value }: { item: CupCodeModel; value: boolean }) {
    this.$startLoading('cupCodeActivation');
    if (value) {
      this.deactivateCupCode({ id: item._id });
    } else {
      this.activateCupCode({ id: item._id });
    }
    this.$stopLoading('cupCodeActivation');
  }

  public async destroy(cupCode: CupCodeModel) {
    this.$startLoading('cupCodeDestroy');
    await this.deleteCupCode({ id: cupCode._id });
    this.$stopLoading('cupCodeDestroy');
  }

  public async exportCupCodes() {
    if (!(await this.validateModel())) return;
    this.$startLoading('exportCodes');
    const api: ExportService = new ExportService();
    api
      .exportCodes(this.exportCodesModel)
      .then((res: any) => {
        toUrl(res.data, `cup-codes-${moment().format('DD-MM-YYYY')}.${this.exportCodesModel.format}`);
      })
      .finally(() => {
        this.exportCodesDialog = false;
        this.resetExportCodesModel();
        this.$refs.observer.reset();
        this.$stopLoading('exportCodes');
      });
  }

  public async removeCupCodes() {
    if (!(await this.validateModel())) return;
    try {
      this.$startLoading('deleteManyCodes');
      const { active, ...restOfObject } = this.actionManyCodesModel;
      await this.deleteMany(restOfObject);
    } catch (e) {
      console.log(e);
    } finally {
      this.resetActionCodesModel();
      this.actionCodesDialog = false;
      this.$stopLoading('deleteManyCodes');
      this.reloadCupCodes();
    }
  }

  public async toggleActiveCupCodes() {
    if (!(await this.validateModel())) return;
    try {
      this.$startLoading('toggleManyActiveCodes');
      await this.toggleManyActive(this.actionManyCodesModel);
    } catch (e) {
      console.log(e);
    } finally {
      this.resetActionCodesModel();
      this.actionCodesDialog = false;
      this.$stopLoading('toggleManyActiveCodes');
      this.reloadCupCodes();
    }
  }

  public async reloadCupCodes() {
    this.$startLoading('initCupCode');
    await this.getCupCodes({ page: 1 });
    this.$stopLoading('initCupCode');
  }

  public resetActionCodesModel() {
    this.actionManyCodesModel.numTo = '';
    this.actionManyCodesModel.numFrom = '';
    this.actionManyCodesModel.customerGroups = [];
    this.actionManyCodesModel.active = false;
  }

  public resetExportCodesModel() {
    this.exportCodesModel.numberFrom = '';
    this.exportCodesModel.numberTo = '';
    this.exportCodesModel.customerGroups = [];
  }

  public validateModel(): Promise<boolean> {
    return this.$refs.observer.validate();
  }

  public exportClose() {
    this.exportCodesDialog = false;
  }

  get typeFile() {
    const items = [
      { text: 'Csv', value: ExportType.CSV },
      { text: 'Xlsx', value: ExportType.XLSX },
      { text: 'ZIP', value: ExportType.ZIP },
    ];
    return items;
  }

  get fromRules() {
    return `${
      this.exportCodesModel.format === 'zip'
        ? 'required|integer|quantity_number_less_10k:@to'
        : 'required|integer|quantity_number_less_100k:@to'
    }`;
  }

  get sortedCustomerGroups() {
    return this.customerGroups.sort((a, b) => {
      const nameA = a.value.toUpperCase();
      const nameB = b.value.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue && this.venue._id) {
      this.$startLoading('cupCode');
      this.setFilter({ venue: this.venue._id });
      await this.getCupCodes({ page: 1 });
      this.$stopLoading('cupCode');
    }
  }
}
