
import { Component, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import Notification from '@/mixins/Notification';
import TableTable from '@/components/table/TableTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { mixins } from 'vue-class-component';
import Filter from '@/interfaces/api/Filter';
import { PromoCode as PromoCodeModel } from '@/interfaces/models/PromoCode';
import PromoCodeTable from '@/components/promo/PromoCodeTable.vue';
import VenueApiService from '@/api/http/VenueApiService';
import { AxiosResponse } from 'axios';
import { User } from '@/interfaces/models/User';
import { CustomerGroup as CustomerGroupEnum } from '@/enums/CustomerGroup';

const app = namespace('app');
const venue = namespace('venue');
const promo = namespace('promo');
const auth = namespace('auth');

@Component({
  components: { PromoCodeTable, VEmptyState, TableTable, VWrapper },
})
export default class PromoCode extends mixins(Notification) {
  @venue.State('active') public venue!: Venue;

  @app.State('venues') public venues!: Venue[];

  @auth.State('user') public user!: User;

  @promo.State('items') public promoCodes!: PromoCodeModel[];
  @promo.State('filter') public filter!: Filter;
  @promo.State((state) => state.pagination.total) public total!: number;
  @promo.Action('fetch') public getPromoCodes!: any;
  @promo.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @promo.Action('activatePromoCode') public activatePromoCode!: (data: { id: string }) => void;
  @promo.Action('enablePromoCode') public enablePromoCode!: any;
  @promo.Action('disablePromoCode') public disablePromoCode!: any;
  @promo.Action('deactivatePromoCode') public deactivatePromoCode!: (data: { id: string }) => void;
  @promo.Action('destroy') public deletePromoCode!: any;

  public venueNames: Array<Partial<Venue>> = [];

  public filterType: number = 0;

  public $refs!: {
    table: InstanceType<typeof PromoCodeTable>;
  };

  public onSearch(search: string) {
    this.setFilter({ ...this.filter, search });
    this.getPromoCodes({ page: 1 });
  }

  public async exportPromoCode() {
    this.$startLoading('promo.export');
  }

  public async exportPromoCodeSummary() {
    this.$startLoading('promo.exportSummary');
  }

  public async destroy(promo: PromoCodeModel) {
    this.$startLoading('promo');
    await this.deletePromoCode({ id: promo._id });
    this.$stopLoading('promo');
  }

  public async getVenueNames() {
    let venues: string[] = [];
    for (const code of this.promoCodes) {
      venues.push(...code.venues);
    }
    venues = [...new Set(venues)];

    const api: VenueApiService = new VenueApiService();
    const promises: Array<Promise<Partial<Venue>>> = [];
    for (const venue of venues) {
      promises.push(api.getVenueName({ id: venue }).then((res: AxiosResponse<Partial<Venue>>) => res.data));
    }

    return await Promise.all(promises);
  }

  public created() {
    this.$startLoading('promo');
  }

  public async toggleActivation({ item, value }: { item: PromoCodeModel; value: boolean }) {
    this.$startLoading('promo');
    if (value) {
      this.deactivatePromoCode({ id: item._id });
    } else {
      this.activatePromoCode({ id: item._id });
    }
    this.$stopLoading('promo');
  }

  public async toggleVenueActivation({ item, value }: { item: PromoCodeModel; value: boolean }) {
    if (this.venue && this.venue._id) {
      this.$startLoading('promo');
      if (value) {
        this.enablePromoCode({ id: item._id, venue: this.venue._id });
      } else {
        this.disablePromoCode({ id: item._id, venue: this.venue._id });
      }
      await this.getPromoCodes({ page: 1 });
      this.$stopLoading('promo');
    }
  }

  public async mounted() {
    this.$startLoading('promo');
    this.setFilter({});
    await this.getPromoCodes({ page: 1 });
    this.$stopLoading('promo');
  }

  @Watch('promoCodes')
  public async onPromoCodesChange() {
    this.$nextTick(async () => {
      this.venueNames = await this.getVenueNames();
    });
  }

  @Watch('filterType')
  public async onFilterTypeChange() {
    this.$startLoading('promo');
    if (this.filterType === 0) {
      this.setFilter({});
    } else if (this.filterType === 1) {
      if (!this.venue) {
        this.notifyInfo('common.selectVenue');
        this.$stopLoading('promo');
        return;
      }
      this.setFilter({ venue: this.venue._id });
    } else if (this.filterType === 2) {
      this.setFilter({ customerGroup: CustomerGroupEnum.TEEDELI });
    }

    await this.$router.push({ query: {} });
    await this.getPromoCodes({ page: 1 });
    this.$stopLoading('promo');
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue && this.venue._id && this.filterType === 1) {
      this.$startLoading('promo');
      this.setFilter({ venue: this.venue._id });
      await this.getPromoCodes({ page: 1 });
      this.$stopLoading('promo');
    }
  }
}
