export default [
  {
    text: 'promo.table.name',
    value: 'name',
  },
  {
    text: 'promo.table.code',
    value: 'code',
  },
  {
    text: 'promo.table.customerGroup',
    value: 'customerGroup',
  },
  {
    text: 'promo.table.countries',
    value: 'countries',
    type: 'array',
  },
  {
    text: 'promo.table.states',
    value: 'states',
    type: 'array',
  },
  {
    text: 'promo.table.venues',
    value: 'venues',
    type: 'slot',
  },
  {
    text: 'promo.table.active',
    value: 'active',
    type: 'slot',
  },
  {
    text: 'promo.table.disabled',
    value: 'disabled',
    type: 'slot',
  },
  {
    text: 'promo.table.from',
    value: 'from',
    type: 'date',
    format: 'DD-MM-YYYY',
  },
  {
    text: 'promo.table.to',
    value: 'to',
    type: 'date',
    format: 'DD-MM-YYYY',
  },
  {
    text: 'promo.table.type',
    value: 'type',
  },
  {
    text: 'promo.table.value',
    value: 'value',
  },
  {
    text: 'promo.table.mov',
    value: 'mov',
    type: 'slot',
  },
  {
    text: 'promo.table.amount',
    value: 'amount',
  },
];
