
import { Component, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import Notification from '@/mixins/Notification';
import TableTable from '@/components/table/TableTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { mixins } from 'vue-class-component';
import Filter from '@/interfaces/api/Filter';
import { Coupon as CouponModel } from '@/interfaces/models/Coupon';
import VenueApiService from '@/api/http/VenueApiService';
import { AxiosResponse } from 'axios';
import { User } from '@/interfaces/models/User';
import CouponTable from '@/components/coupon/CouponTable.vue';

const app = namespace('app');
const venue = namespace('venue');
const coupon = namespace('coupon');
const auth = namespace('auth');

@Component({
  components: { CouponTable, VEmptyState, TableTable, VWrapper },
})
export default class Coupon extends mixins(Notification) {
  @venue.State('active') public venue!: Venue;

  @app.State('venues') public venues!: Venue[];

  @auth.State('user') public user!: User;

  @coupon.State('items') public coupons!: CouponModel[];
  @coupon.State('filter') public filter!: Filter;
  @coupon.State((state) => state.pagination.total) public total!: number;
  @coupon.Action('fetch') public getCoupons!: any;
  @coupon.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @coupon.Action('activateCoupon') public activateCoupon!: (data: { id: string }) => void;
  @coupon.Action('enableCoupon') public enableCoupon!: any;
  @coupon.Action('disableCoupon') public disableCoupon!: any;
  @coupon.Action('deactivateCoupon') public deactivateCoupon!: (data: { id: string }) => void;
  @coupon.Action('destroy') public deleteCoupon!: any;

  public venueNames: Array<Partial<Venue>> = [];

  public filterType: number = 0;

  public $refs!: {
    table: InstanceType<typeof CouponTable>;
  };

  public onSearch(search: string) {
    this.setFilter({ ...this.filter, search });
    this.getCoupons({ page: 1 });
  }

  public async exportCoupon() {
    this.$startLoading('coupon.export');
  }

  public async exportCouponSummary() {
    this.$startLoading('coupon.exportSummary');
  }

  public async destroy(coupon: CouponModel) {
    this.$startLoading('coupon');
    await this.deleteCoupon({ id: coupon._id });
    this.$stopLoading('coupon');
  }

  public async getVenueNames() {
    let venues: string[] = [];
    for (const code of this.coupons) {
      venues.push(...code.venues);
    }
    venues = [...new Set(venues)];

    const api: VenueApiService = new VenueApiService();
    const promises: Array<Promise<Partial<Venue>>> = [];
    for (const venue of venues) {
      promises.push(api.getVenueName({ id: venue }).then((res: AxiosResponse<Partial<Venue>>) => res.data));
    }

    return await Promise.all(promises);
  }

  public created() {
    this.$startLoading('coupon');
  }

  public async toggleActivation({ item, value }: { item: CouponModel; value: boolean }) {
    this.$startLoading('coupon');
    if (value) {
      this.deactivateCoupon({ id: item._id });
    } else {
      this.activateCoupon({ id: item._id });
    }
    this.$stopLoading('coupon');
  }

  public async toggleVenueActivation({ item, value }: { item: CouponModel; value: boolean }) {
    if (this.venue && this.venue._id) {
      this.$startLoading('coupon');
      if (value) {
        this.enableCoupon({ id: item._id, venue: this.venue._id });
      } else {
        this.disableCoupon({ id: item._id, venue: this.venue._id });
      }
      await this.getCoupons({ page: 1 });
      this.$stopLoading('coupon');
    }
  }

  public async mounted() {
    this.$startLoading('coupon');
    await this.getCoupons({ page: 1 });
    this.$stopLoading('coupon');
  }

  @Watch('coupons')
  public async onCouponsChange() {
    this.$nextTick(async () => {
      this.venueNames = await this.getVenueNames();
    });
  }

  @Watch('filterType')
  public async onFilterTypeChange() {
    this.$startLoading('coupon');
    if (this.filterType === 0) {
      this.setFilter({});
    } else if (this.filterType === 1) {
      if (!this.venue) {
        this.notifyInfo('common.selectVenue');
        this.$stopLoading('coupon');
        return;
      }
      this.setFilter({ venue: this.venue._id });
    }
    await this.getCoupons({ page: 1 });
    this.$stopLoading('coupon');
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue && this.venue._id && this.filterType === 1) {
      this.$startLoading('coupon');
      this.setFilter({ venue: this.venue._id });
      await this.getCoupons({ page: 1 });
      this.$stopLoading('coupon');
    }
  }
}
