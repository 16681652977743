export default [
  {
    text: 'cupCode.table.customerGroup',
    value: 'customerGroups',
    type: 'slot',
  },
  {
    text: 'cupCode.table.number',
    value: 'number',
  },
  {
    text: 'cupCode.table.qrCode',
    value: 'qrCode',
    type: 'slot',
  },
  {
    text: 'cupCode.table.createdAt',
    value: 'createdAt',
    type: 'slot',
  },
  {
    text: 'cupCode.table.active',
    value: 'active',
    type: 'slot',
  },
];
